import { render, staticRenderFns } from "./downloadCount.vue?vue&type=template&id=028f0285&scoped=true"
import script from "./downloadCount.vue?vue&type=script&lang=js"
export * from "./downloadCount.vue?vue&type=script&lang=js"
import style0 from "./downloadCount.vue?vue&type=style&index=0&id=028f0285&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "028f0285",
  null
  
)

export default component.exports